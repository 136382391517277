import type HTMLAnchorElement from 'happy-dom/lib/nodes/html-anchor-element/HTMLAnchorElement'
import { isLegacyGraph, isLegacyResultat } from 'src/lib/core/checks'
import Graph, { type GraphSerialized, type GraphValues } from 'src/lib/entities/Graph'

import type { LegacyGraph, LegacyResultat, Resultat } from 'src/lib/types'
import { showError } from 'src/lib/utils/dom/main'

export type Action = 'edit' | 'play' | 'show'
export type Version = '1' | '2'

interface RunData {
  action: Action,
  version: Version
  graph: GraphValues
  resultat?: Resultat
}

export const versions = ['1', '2']
export const actions = ['edit', 'play', 'show']
// const argsNames = ['action', 'version', 'graph', 'resultat', 'rid', 'debug']

let lastGraph: Graph | GraphValues | LegacyGraph | null = null
let lastResultat: Resultat | LegacyResultat | null = null
let lastVersion: Version = '2'

export const getArgs = (): Record<string, string> => {
  const args: Record<string, string> = {}
  if (window.location.hash.length > 1) {
    const chunks = window.location.hash.substring(1).split('&')
    for (const str of chunks) {
      if (str.includes('=')) {
        const [k, v] = str.split('=')
        args[k as string] = v as string
      } else {
        args[str] = '1'
      }
    }
  }
  return args
}

const ge = (id: string): HTMLElement | null => document.getElementById(id)

/**
 * Affecte le contexte, envoie les infos à l’iframe et met à jour les onglets actifs / inactifs
 */
function post (action: Action, version: Version, arg: Object): void {
  const iframe = document.body.querySelector('iframe')
  if (typeof iframe?.contentWindow?.postMessage !== 'function') throw Error('Aucune iframe disponible')
  const { debug, mtgUrl } = getArgs()
  const isDebug = debug === '1'
  if (isDebug) {
    console.debug('post avec', { action, version, arg, isDebug, mtgUrl })
  }
  if (typeof iframe?.contentWindow?.postMessage === 'function') {
    // affichage de l’iframe dans son #iframeContainer
    if (iframe?.parentElement != null) iframe.parentElement.style.display = 'flex'
    // avant le post
    iframe.contentWindow.postMessage({ action, version, arg, isDebug, mtgUrl })
  } else {
    console.error(Error('pas de postMessage vers l’iframe possible'))
  }
}

interface SetContextOptions {
  graph?: GraphValues | LegacyGraph | Graph
  resultat?: Resultat | LegacyResultat
  version?: Version
}

/**
 * Affecte le contexte courant
 */
export function setContext ({ resultat, graph, version }: SetContextOptions): void {
  if (version) lastVersion = version
  let graphValues: GraphValues | LegacyGraph | Graph | null
  if (resultat) {
    lastResultat = resultat
    graphValues = isLegacyResultat(resultat)
      ? resultat?.contenu?.graphe as LegacyGraph
      : resultat?.contenu?.pathway?.graph as GraphSerialized
  } else {
    lastResultat = null
    graphValues = graph ?? null
  }
  // on instantie le graph pour lancer le validate
  if (graphValues != null) {
    lastGraph = graphValues instanceof Graph
      ? graphValues
      : isLegacyGraph(graphValues)
        ? graphValues
        : new Graph(graphValues)
    if (lastGraph instanceof Graph) {
      lastGraph.validate({ clean: true })
        .then(({ warnings, errors }) => {
          if (errors.length) throw Error('Graphe invalide : ' + errors.join('\n'))
          if (warnings.length > 1) {
            console.warn(`Le graphe a des avertissements : ${warnings.join('\n')}`)
          }
        })
        .catch(error => {
          console.error('plantage du validate avec', graphValues)
          lastGraph = null
          showError(error)
        })
    }
  } else {
    lastGraph = null
  }
  // màj des onglets
  tab('')
}

/**
 * Affiche le div correspondant à l’onglet tabName (si tabName n’est pas fourni on se contente de màj les onglets disabled ou pas suivant le contexte)
 */
export function tab (tabName: string): void {
  const tabs = document.body.querySelectorAll('menu a')
  let i = 0
  for (const div of document.body.querySelectorAll('#main div')) {
    const link = tabs[i] as unknown as HTMLAnchorElement
    if (tabName) {
      if (div.id === `${tabName}Container`) {
        link?.classList.add('active')
        div.classList.add('active')
      } else {
        link?.classList.remove('active')
        div.classList.remove('active')
      }
    }
    // dans tous les cas on rafraîchit les href des onglets
    let href = ''
    if (i === 2) { // edit
      href = lastGraph
        ? `#action=edit&version=${lastVersion}&graph=${encodeURIComponent(JSON.stringify(lastGraph))}`
        : '#'
    } else if (i === 3) { // play
      if (lastResultat) {
        href = `#action=play&version=${lastVersion}&resultat=${encodeURIComponent(JSON.stringify(lastResultat))}`
      } else if (lastGraph) {
        href = `#action=play&version=${lastVersion}&graph=${encodeURIComponent(JSON.stringify(lastGraph))}`
      } else {
        href = '#'
      }
    } else if (i === 4) { // show
      href = lastResultat
        ? `#action=show&version=${lastVersion}&resultat=${encodeURIComponent(JSON.stringify(lastResultat))}`
        : '#'
    }
    if (href && link != null) {
      link.href = href
      if (href.length > 1) {
        link?.classList.remove('disabled')
      } else {
        link?.classList.add('disabled')
      }
    }
    i++
  }

  // on ne masque l’iframe que si l’on a un tabName, sinon on la laisse comme elle est
  if (tabName) {
    const iframe = document.body.querySelector('iframe')
    if (iframe?.parentElement != null) iframe.parentElement.style.display = 'none'
  }
}

async function edit (version: Version, graph: GraphValues | LegacyGraph) {
  tab('edit')
  const ct = ge('editContainer')
  if (ct == null) throw Error('#editContainer manquant')
  if (version === '1') {
    if (isLegacyGraph(graph)) {
      post('edit', '1', { parametres: { g: graph } })
    } else {
      const { convertGraphBack } = await import('src/lib/core/convert2To1')
      const { g, editgraphes } = await convertGraphBack(graph instanceof Graph ? graph : new Graph(graph))
      post('edit', '1', { parametres: { g, editgraphes } })
    }
  } else {
    // v2
    if (isLegacyGraph(graph)) {
      post('edit', '2', { parametres: { g: graph } })
    } else {
      post('edit', '2', { parametres: { graph } })
    }
  }
}

/**
 * Lance le player v1 ou v2
 */
async function play ({ version, graph, resultat }: Partial<RunData>): Promise<void> {
  tab('play')
  if (version === '1') {
    if (resultat) {
      // reprise de parcours
      if (!isLegacyResultat(resultat)) {
        throw Error('Le player v1 ne peut faire de reprise de parcours qu’avec un résultat au format v1')
      }
      post('play', '1', { graphe: resultat.contenu.graphe, lastResultat: resultat })
    }
    // c’est un graphe
    if (graph == null) throw Error('Il faut passer un résultat ou un graphe')
    if (isLegacyGraph(graph)) {
      post('play', '1', { graphe: graph })
    } else {
      const { convertGraphBack } = await import('src/lib/core/convert2To1')
      const graphV2 = graph instanceof Graph ? graph : new Graph(graph)
      const { g, editgraphes } = await convertGraphBack(graphV2)
      post('play', '1', { graphe: g, editgraphes })
    }
  } else {
    // v2
    if (resultat) {
      post('play', '2', { lastResultat: resultat })
    } else if (graph) {
      post('play', '2', { graph })
    }
  }
}

/**
 * Lance l’afficheur de parcours v2, ou v1 si le paramètre d’url `v1` est présent
 * (dans le cas v1 le résultat doit être au format v1)
 */
async function show (resultat: Resultat | LegacyResultat, version: Version): Promise<void> {
  tab('show')
  if (version === '1') {
    if (!isLegacyResultat(resultat)) {
      return showError(Error('Pour l’affichage v1 d’un parcours, il faut fournir le résultat en v1'))
    }
    // showParcours veut le contenu
    post('show', '1', resultat.contenu)
  } else {
    // v2
    if (isLegacyResultat(resultat)) {
      const { convertResultat } = await import('src/lib/core/convert1to2')
      resultat = convertResultat(resultat)
    }
    // spView veut un resultat…
    post('show', '2', resultat)
  }
}

// la fct exportée qui fait tout

/**
 * Lance l’affichage (play|edit|show en v1|v2 suivant les params qu’on lui passe)
 */
export async function run ({ action, version, graph, resultat }: RunData): Promise<void> {
  try {
    if (action === 'show') {
      // on veut un résultat
      if (resultat == null) return showError(Error('résultat manquant'))
      setContext({ resultat, version })
      await show(resultat, version)
    } else {
      // Ces comparaisons doivent être faites en amont (dans start.ts qui appelle run après avoir vérifié ses arguments.
      // on veut un graphe
      if (graph == null) return showError(Error('graphe manquant'))
      setContext({ resultat, graph, version })
      if (action === 'edit') {
        await edit(version, graph)
      } else {
        if (resultat != null) {
          await play({ version, graph, resultat })
        } else {
          await play({ version, graph })
        }
      }
    }
  } catch (error) {
    showError(error)
  }
}
