import { Action, actions, getArgs, run, setContext, tab, Version, versions } from 'src/dev/start.runners'
import { loadBibli } from 'src/lib/core/bibli'
import { isLegacyRessourceJ3pParametres, normalizeGraph, normalizeResultat } from 'src/lib/core/checks'
import Graph, { compareAlreadyRunWithGraph, GraphValues } from 'src/lib/entities/Graph'

import 'roboto-fontface/css/roboto/sass/roboto-fontface-regular.scss'
import './start.scss'
import { Resultat } from 'src/lib/types'
import { addElement, empty, showError } from 'src/lib/utils/dom/main'
import { safeEval } from 'src/lib/utils/object'
import { sleep } from 'src/lib/utils/promise'

/**
 * js exécuté uniquement par docroot/index.html, pour déléguer ensuite aux autres fcts globales mises par loader.js
 * - j3pLoad si on a un graphe|section|rid
 * - editGraphe si y’a du edit (ou du &param qui réécrit avec edit)
 * - startForm.js si y’a pas d’argument, pour le form qui demande ce qu’il faut charger
 * (tous seront chargé à la demande)
 */

const delay = 200
const maxWaits = 10

function ensureActionAndVersion (): boolean {
  const args = getArgs()
  let hashToAdd = ''
  if (!versions.includes(args.version ?? '')) {
    hashToAdd += '&version=2'
  }
  if (!actions.includes(args.action ?? '')) {
    hashToAdd += '&action=play'
  }
  if (hashToAdd) {
    window.location.hash += hashToAdd
    return false
  }
  return true
}

/**
 * Charge les contenus d’après options ou l’url, si rien n’est précisé ça affiche le form pour lister les sections
 * @return {Promise} Promesse résolue quand le chargement est terminé
 */
async function start (): Promise<void> {
  // pour compatibilité ascendante, si on a une searchString on la converti en hash
  if (window.location.search.length > 0) {
    window.location.href = `/#${window.location.search.substring(1)}`
    return
  }

  // les arguments du hash
  const args = getArgs()
  const isDebug = args.debug === '1'
  const logIfDebug = isDebug
    ? console.debug.bind(console)
    : () => undefined

  logIfDebug(`start avec ${window.location.hash || '#'}#`, args)

  // on affecte ça en global si on nous le demande
  if (args.mtgUrl) {
    window.mtgUrl = args.mtgUrl
  }

  // #wait signifie ne rien faire, en attendant qu’un window.xxx() soit appelé par ailleurs
  // (testBrowser utilise ça)
  if (args.wait) {
    logIfDebug('wait demandé, on charge lazyLoader et on attend.')
    // il faut tout virer et ne remettre qu’un #playContainer
    empty(document.body)
    addElement(document.body, 'div', { id: 'playContainer' })
    // puis charger lazyLoader pour déclarer nos fcts globales (et attendre qu’elles soient appelées)
    // (c’est pas un module, on est au courant et osef car on récupère rien)
    // @ts-ignore TS7016: Could not find a declaration file for module …
    await import('src/lazyLoader')
    return
  }

  // faut afficher le form ?
  if (args.action === 'form') {
    const { default: showForm } = await import('src/dev/start.form')
    await showForm()
    return
  }

  // un raccourci pour appeler run avec un graphe
  const runGraph = (graph: GraphValues): void => {
    run({ action: args.action as Action, version: args.version as Version, graph })
      .catch(showError)
    // on retourne en sync
  }
  const runGraphWithResultat = (graph: GraphValues, resultat: Resultat): void => {
    run({ action: args.action as Action, version: args.version as Version, graph, resultat })
      .catch(showError)
    // on retourne en sync
  }

  // chargement d’après un rid
  if (args.rid) {
    const { rid } = args
    if (ensureActionAndVersion()) {
      logIfDebug(`On va chercher la ressource ${rid}`)
      const { ressource: { parametres } } = await loadBibli(rid)
      logIfDebug(`on a récupéré ${rid} avec les params`, parametres)
      let graph: Graph | null = null
      if (isLegacyRessourceJ3pParametres(parametres)) {
        if (args.testBrowser) {
          // test de chargement, on charge lazy et on charge le rid demandé sans passer par la conversion
          empty(document.body)
          const ct = addElement(document.body, 'div')
          // @ts-ignore TS7016: Could not find a declaration file for module …
          await import('src/lazyLoader')
          if (typeof window.j3pLoad === 'function') window.j3pLoad(ct, { graphe: parametres.g })
          else console.error(Error('sesaparcours n’est pas correctement chargé'))
          return
        }
        graph = await normalizeGraph(parametres.g, parametres.editgraphes)
      } else if ('graph' in parametres) {
        graph = new Graph(parametres.graph)
      }
      if (graph == null) throw Error(`La ressource ${rid} ne contient pas de graphe`)
      runGraph(graph)
    }
    return
  }

  // chargement d’après un nom de section
  if (args.section) {
    if (ensureActionAndVersion()) {
      const graph = new Graph({
        nodes: { n1: { section: args.section } },
        startingId: 'n1'
      })
      runGraph(graph)
    }
    return
  }
  let toBeContinued = false
  // chargement d’après graphe ou resultat passé dans le hash
  if (args.graph || args.resultat) {
    // on veut action et resultat
    if (!ensureActionAndVersion()) return

    // chargement d’après un graphe passé dans l’url
    if (args.graph) {
      const grapheStr = decodeURIComponent(args.graph)
      let graphValues: GraphValues
      try {
        graphValues = JSON.parse(grapheStr)
      } catch (error) {
        try {
          // c’est pas forcément du json, on accepte du js, mais avec une évaluation isolée
          graphValues = safeEval(grapheStr) as GraphValues
        } catch (error) {
          console.error('graphe invalide :', grapheStr, error)
          return showError('graphe invalide dans l‘url')
        }
      }
      const graph = await normalizeGraph(graphValues)
      let resultat: Resultat
      if (args.resultat) {
        try {
          resultat = normalizeResultat(JSON.parse(decodeURIComponent(args.resultat)))
          if (!compareAlreadyRunWithGraph(graph, resultat)) {
            console.warn('Résultats et graphe incompatibles, on recommence le graph au début.')
          } else {
            console.info('Le graphe demandé et celui présent dans les résultats sont compatibles, on peut poursuivre ce parcours.')
            toBeContinued = true
          }
        } catch (error) {
          console.error('resultat invalide :', decodeURIComponent(args.resultat), error)
          return showError('resultat invalide dans l‘url')
        }
        if (toBeContinued) {
          runGraphWithResultat(graph, JSON.parse(decodeURIComponent(args.resultat)))
        }
      } else {
        runGraph(graph)
      }
    } else if (args.resultat) {
      const resultatStr = decodeURIComponent(args.resultat)
      let resultat
      let graph
      try {
        resultat = JSON.parse(resultatStr)
        graph = resultat.contenu.pathway.graph
      } catch (error) {
        try {
          // c’est pas forcément du json, on accepte du js, mais avec une évaluation isolée
          resultat = safeEval(resultatStr)
        } catch (error) {
          console.error('graphe invalide :', resultatStr, error)
          return showError('graphe invalide dans l‘url')
        }
      }
      runGraphWithResultat(graph, resultat)
    }
    return
  }

  // si on a rien rencontré on affiche la description
  tab('desc')
} // start

function changeHash (args: Record<string, string>): void {
  const keysValues = Object.entries(args).map(([k, v]) => `${k}=${v}`)
  window.location.hash = `#${keysValues.join('&')}`
}

function changeVersion (newVersion: Version): void {
  const args = getArgs()
  if (newVersion === args.version) return
  args.version = newVersion
  changeHash(args)
}

let isIframeReady = false
const onIframeReady = () => {
  isIframeReady = true
  // le listener sur le hash
  window.addEventListener('hashchange', start)
  // et on démarre
  start().catch(showError)
}

// et on lance notre fct principale dès que l’iframe a son listener
(async () => {
  // on écoute les messages de l’iframe
  window.addEventListener('message', (event) => {
    const action = event.data?.action ?? ''
    if (action === 'pong') {
      onIframeReady()
    } else if (actions.includes(action)) {
      const version = versions.includes(event.data?.version ?? '') ? event.data.version : '2'
      // l’iframe (éditeur) nous demande de mettre à jour le hash, qui va mettre à jour le reste
      window.location.hash = `#action=${action}&version=${version}&graph=${encodeURIComponent(JSON.stringify(event.data.graph))}`
    } else if (action === 'setResultat') {
      setContext({ resultat: event.data.resultat })
    }
  })

  // on ajoute une seule fois nos boutons radios dans les conteneurs
  const form: HTMLElement | null = document.body.querySelector('#iframeContainer form')
  if (form == null) {
    console.error(Error('Pas trouvé #iframeContainer form'))
  } else {
    const args = getArgs()
    const isV1 = args?.version === '1'
    const isDebug = args.debug === '1'
    const label1 = addElement(form, 'label', { textContent: 'Version 1' })
    addElement(label1, 'input', { type: 'radio', name: 'version', value: '1', checked: isV1 })
    label1.addEventListener('click', changeVersion.bind(null, '1'))
    const label2 = addElement(form, 'label', { textContent: 'Version 2' })
    addElement(label2, 'input', { type: 'radio', name: 'version', value: '2', checked: !isV1 })
    label2.addEventListener('click', changeVersion.bind(null, '2'))
    const labelDebug = addElement(form, 'label', { textContent: 'Debug' })
    addElement(labelDebug, 'input', { type: 'checkbox', name: 'debug', checked: isDebug })
      .addEventListener('click', ({ currentTarget }) => {
        if (currentTarget == null) return
        const isChecked = (currentTarget as HTMLInputElement).checked
        // faut le relire
        const isDebug = getArgs()?.debug === '1'
        if (isChecked === isDebug) return
        // faut changer le hash
        const args = getArgs()
        args.debug = isChecked ? '1' : '0'
        changeHash(args)
      })
    form.addEventListener('submit', (event) => {
      event.preventDefault()
    })
  }

  const iframe = document.querySelector('iframe')
  if (iframe == null) {
    showError(Error('Pas d’iframe dans le document courant, impossible de tester'))
  } else {
    let nbWaits = 0
    // eslint-disable-next-line no-unmodified-loop-condition
    while (nbWaits < maxWaits && !isIframeReady) {
      nbWaits++
      iframe.contentWindow?.postMessage({ action: 'ping' })
      await sleep(delay)
    }
    if (!isIframeReady) {
      showError(Error(`L’iframe n’est toujours pas prête après ${nbWaits * delay}ms d’attente`))
    }
  }
})()
